<!-- eslint-disable vue/require-prop-types -->
<script setup>
import { watch } from "vue";

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  openModal: { Boolean, default: false },
});

watch(
  () => props.openModal,
  () => {
    if (props.openModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
);
</script>

<template>
  <div v-if="props.openModal" class="modal-wrapper" @click="emit('closeModal')">
    <div class="modal-conteudo card" @click.stop>
      <button
        class="ms-auto bg-secondary btn btn-close"
        @click="emit('closeModal')"
      ></button>
      <slot />
    </div>
  </div>
</template>

<style scoped>
.modal-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 203;
  left: 0;
  top: 0;
}

.modal-conteudo {
  width: 100%;
  max-width: 570px;
  background-color: white;
  padding: 30px;
  position: relative;
}
.btn-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
