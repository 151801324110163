<!-- eslint-disable vue/require-default-prop -->
<script setup>
// interface Column {
//   dataField: string
//   dataType: string
//   caption: string
//   dataAlign?: 'left' | 'center' | 'right'
//   headerAlign?: 'left' | 'center' | 'right'
//   width?: string
// }

const props = defineProps({
  columns: { type: Array },
  rows: { type: Array },
  title: { type: String },
  corTitulo: { type: String },
});
</script>

<template>
  <div class="area-table">
    <h1 v-if="props.title" class="text-title text-center py-3 mb-0">
      {{ props.title }}
    </h1>
    <div v-if="props.columns.length > 0" class="row-table table-ganhadores">
      <p
        v-for="(col, index) in props.columns"
        :key="String(index)"
        class="text-sm font-500 area-data"
        :style="{
          width: col.width,
          textAlign: col.headerAlign ? col.headerAlign : 'left',
        }"
      >
        {{ col.caption }}
      </p>
    </div>

    <div class="table-body">
      <div
        v-for="(item, index) in props.rows"
        :key="String(index)"
        class="row-table list-ganhadores"
      >
        <p
          v-for="(col, indexCol) in columns"
          :key="`${String(indexCol)}I`"
          class="text-sm area-data"
          :style="{
            width: col.width,
            textAlign: col.dataAlign ? col.dataAlign : 'left',
          }"
        >
          {{ item[col.dataField] }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.area-table {
  /* width: 100%; */
  /* padding: 30px; */
  background-color: white;
}
.table-body .row-table:nth-child(odd) {
  background-color: #e3e3e3;
}
.row-table {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 15px;
}

.area-data {
  /* width: 150px; */
  /* white-space: normal; */
  word-break: break-all;
}

.table-ganhadores {
  background-color: #9B0024;
}
.table-ganhadores p {
  font-size: 25px !important;
  color: #fff;
  font-weight: bold !important;
}
.text-head-ganhadores {
  font-size: 25px;
  color: #fff;
  font-weight: 400;
}
.text-title {
  color: #fff;
  font-size: 56px;
  font-weight: 700;
  background-color: #9B0024;
}
.list-ganhadores p {
  color: #FF0B46;
  font-size: 20px !important;
  font-weight: 600;
}

.empty h5 {
  font-size: 25px;
}

@media (max-width: 1025px) {
  .row-table {
    gap: 5px;
    padding: 17px;
  }
  .table-ganhadores p {
    font-size: 15px !important;
  }
  .list-ganhadores p {
    font-size: 16px !important;
  }
}
@media (max-width: 540px) {
  .text-title {
    font-size: 22px;
  }
  .row-table {
    padding: 15px;
  }
  .table-ganhadores p {
    font-size: 12px !important;
  }
  .list-ganhadores p {
    font-size: 14px !important;
  }
}
@media (max-width: 385px) {
  .text-title {
    font-size: 20px;
  }

  .table-ganhadores p {
    font-size: 11px !important;
  }
  .list-ganhadores p {
    font-size: 12px !important;
  }
}
</style>
