<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex justify-content-center"> <img class="img-fluid w-75 desktop promo-img-desktop" src="../assets/images/produto-dobra.png"
          alt="Capanha" />
      </div>
      <div class="col-md-6 d-flex align-items-center">
        <div id="form_cpf" class="d-flex justify-content-center container-fluid">
          <form class="text-center pt-4">
            <label for="cpf" class="label-cpf mb-4">Informe o seu CPF.</label>
            <input id="cpf" v-model="cpf" v-maska="'###.###.###-##'" name="cpf" placeholder="*CPF"
              class="mb-4 form-control" type="text" />
            <div>
              <button class="btn-default mb-4" @click.prevent="handleSubmitCPF">
                Acessar
              </button>
            </div>

            <!-- <button><img class="img-fluid" src="../assets/images/enviouWpp.png" alt="Enviou cupom por whatsapp"></button> -->
            <!-- <p class="mb-4">ENVIOU O CUPOM PELO WHATSAPP?</p>
      <button class="btn-default">CLIQUE PARA VER OS Nº DA SORTE</button> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import swal from "sweetalert2";
import { ref, onMounted } from "vue";

const cpf = ref("");

const emit = defineEmits(["submitCPF"]);

function handleSubmitCPF() {
  const cpfLimpo = cpf.value
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  if (!cpfLimpo) {
    swal.fire({
      text: "CPF vazio, verifique e tenta novamente",
      icon: "warning",
    });
    return;
  }
  if (!cpfLimpo.length === 11 && !cpfLimpo.length === 14) {
    swal.fire({
      text: "CPF incompleto, verifique e tenta novamente",
      icon: "warning",
    });
    return;
  }
  emit("submitCPF", cpfLimpo);
}

onMounted(() => {
  document.getElementById("cpf").focus();
});
</script>

<style scoped>
#cpf {
  text-align: center;
}

form {
  width: 100%;
  max-width: 510px;
  color: #fff;
}

.label-cpf {
  color: white !important;
}

.label-cpf,
form p {
  display: block;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
}

.btn-default {
  font-weight: 400;
  font-size: 22px;
  margin: 0 auto;
}

.btn-default:hover {
  transform: scale(1.1);
}
</style>
