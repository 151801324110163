import { defineStore } from "pinia";
import { http } from "@/services/api";

export const storeProdutos = defineStore("produtos", {
  state: () => ({
    produtosSelect: [],
    produtos: [],
    columns: [],
  }),
  actions: {
    ActionGetProdutos() {
      if (this.produtos.length > 0) return;

      this.produtosSelect = [];
      this.produtos = [];
      this.columns = [];
      const url = "/produtos";

      return http.get(url).then((r) => {
        // this.produtosSelect = []

        // this.produtos = []
        // const itemsProdutos = [];

        // const keysProd = Object.keys(r.data.produtos)

        // for (const key of keysProd) {
        //     r.data.produtos[key].map(prod => {
        //         console.log(prod)
        //         itemsProdutos.push(prod)
        //     })

        // }

        // this.produtos = itemsProdutos;
        
        const header = Object.keys(r.data.produtos);
        // const itemsProdutos = [];
        for (const key of header) {
          this.columns.push({
            dataField: key,
            caption: key,
            dataType: "string",
            width: "100%",
            headerAlign: "center",
            dataAlign: "center",
            rows: r.data.produtos[key]
          });
          // for (let i = 0; i < r.data.produtos[key].length; i++) {
          //   if (itemsProdutos[i]) {
          //     itemsProdutos[i][key] = r.data.produtos[key][i].name;
          //   } else {
          //     itemsProdutos[i] = {
          //       [key]: r.data.produtos[key][i].name,
          //     };
          //   }
          // }
        }

        // this.produtos = itemsProdutos;
      });
    },
    ActionGetListSelect() {
      const url = "/produtosSelect";
      return http.get(url).then((r) => {
        const list = r.data.produtos.map((x) => {
          return { label: `${x.name} - ${x.cod_barras}`, value: x.id };
        });
        this.produtosSelect = list;
      });
    },
  },
});
