<script setup>
import { onMounted, ref, computed } from "vue";
import { storeProdutos } from "@/store/produtosParticipantes";
import { storeCupons } from "@/store/cupons";
import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import PdfViewer from "./PdfViewer.vue";

const produtosStore = storeProdutos();
const cuponsStore = storeCupons();
const emit = defineEmits(["handleChange", "cadastrar"]);

const dados = ref({
  cnpjLoja: "",
  produtos: [{ name: "", qtd: "" }],
});
const linkPreview = ref("");
const pdfUrl = ref("");
const disabledBtnSend = computed(() => {
  let disabled = false
  if (!dados.value.acceptTerms || !dados.value.imgCoupom) {
    disabled = true
  }
  return disabled
})
function validaCnpj() {
  let valido = validaCpfCnpj(
    dados.value.cnpjLoja.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "")
  );

  if (!valido) {
    dados.value.cnpjLoja = "";
    window.__Swal.fire({
      icon: "warning",
      title: "Atenção",
      text: "CNPJ inválido",
    });
  }
}

function addProduto() {
  dados.value.produtos.push({ name: "", qtd: "" });
}
function removerProduto(index) {
  dados.value.produtos.splice(index, 1);
}
function previewImg(file) {
  let reader = new FileReader();
  reader.onload = (e) => {
    linkPreview.value = e.target.result;
  };
  reader.readAsDataURL(file);
}

// function handleChange() {
//   emit("handleChange", dados.value);
// }
function handleCadastro() {
  emit("cadastrar", dados.value);
}

async function handleImg(event) {
  let files = event.target.files || event.dataTransfer.files;
  if (!files.length) return;

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  dados.value.imgCoupom = await toBase64(files[0]);

  previewImg(files[0]);
  if (files[0].type === "application/pdf") {
    renderizarPDF(files[0]);
  } else {
    pdfUrl.value = "";
  }
  //   handleChange();
}
async function renderizarPDF(file) {
  if (file) {
    const reader = new FileReader();

    reader.onload = function (event) {
      pdfUrl.value = event.target.result;
    };

    reader.readAsDataURL(file);
  }
}

onMounted(() => {
  produtosStore.ActionGetListSelect();
});
</script>

<template>
  <div class="row">
    <div class="col-md-6">
      <label for="cnpj">CNPJ DA LOJA</label>
      <input
        id="cnpj"
        v-model="dados.cnpjLoja"
        v-maska="'##.###.###/####-##'"
        name="cnpj"
        class="mb-4 form-control"
        type="text"
        @blur="validaCnpj"
      />
    </div>
    <div class="col-md-6">
      <label for="nCupon">NÚMERO DO COO (CUPOM FISCAL)</label>
      <input
        id="nCupon"
        v-model="dados.cupomNumero"
        name="nCupon"
        class="mb-4 form-control"
        type="number"
      />
    </div>
  </div>

  <div>
    <label class="text-start">PRODUTOS QUE VOCÊ COMPROU*</label>
    <div
      v-for="(produto, index) in dados.produtos"
      :key="String(index)"
      class="row position-relative"
    >
      <button class="btn-close" @click.prevent="removerProduto(index)"></button>
      <div class="col-md-6">
        <v-select
          v-model="produto.name"
          class="mb-4 form-control select"
          style="height: 60px"
          :options="produtosStore.produtosSelect"
        />
        <!-- <input
          id="nCupon"
          v-model="produto.name"
          placeholder="Digite o nome como está no cupom"
          name="nCupon"
          class="mb-4 form-control"
          type="text"
        /> -->
      </div>
      <div class="col-md-6 mt-3 mt-sm-0">
        <input
          id="nCupon"
          v-model="produto.qtd"
          placeholder="Quantidade"
          name="nCupon"
          class="mb-4 form-control"
          type="number"
        />
      </div>
    </div>

    <p class="text-center text-white">
      Precisa adicionar mais produtos?
      <button class="btn-add" @click.prevent="addProduto">Clique aqui</button>
    </p>

    <p class="text-bold text-white text-center my-5">
      ATENÇÃO: A cada produto comprado você ganha 1 número da sorte, caso esses campos acima sejam preenchidos errados ou
      diferentes da foto do seu cupom fiscal, você será automaticamente
      desclassificado (a) conforme descrito no regulamento.
    </p>

    <p class="text-bold text-white text-center text-img">
      Insira uma foto nítida e legível do seu Cupom Fiscal, será utilizada para
      a conferência e auditoria.
    </p>

    <label class="label-file my-4 anima-scale1">
      <p class="ms-2">Selecione uma foto</p>
      <span>Enviar imagem</span>
      <input
        type="file"
        accept="image/*,.pdf"
        class="sr-only"
        @change="handleImg"
      />
    </label>

    <p class="text-bold text-white text-center text-img">
      ATENÇÃO: Fotos ilegíveis, cortadas ou com problemas serão automaticamente
      desclassificadas, confira no botão ao lado a sua imagem e se precisar
      envie novamente.
    </p>

    <p v-if="!linkPreview" class="text-bold text-white text-center mb-4">
      A imagem que enviou será visualizada conforme abaixo:
    </p>

    <div v-if="pdfUrl" style="max-width: 800px" class="m-auto my-4">
      <pdf-viewer :pdf-url="pdfUrl" />
    </div>
    <div v-else class="d-flex justify-content-center pb-4">
      <img
        v-if="linkPreview"
        class="img-fluid"
        :src="linkPreview"
        alt="Imagem cupom fiscal"
      />

      <img
        v-else
        class="img-fluid"
        src="../assets/images/img-cupom.png"
        alt="Imagem cupom fiscal"
      />
    </div>

    <div class="d-flex justify-content-center">
      <label class="area-check mb-4">
        <div>
          <input v-model="dados.acceptTerms" type="checkbox" class="checkbox" />
        </div>

        <p class="text-product">
          <strong>Selecione para aceitar: </strong>Declaro que li e aceito as
          condições do regulamento, política de privacidade e termos de uso do
          site.
        </p>
      </label>
    </div>

    <div class="d-flex justify-content-center my-5">
      <button
        v-if="cuponsStore.loading"
        class="btn-default"
        @click.prevent
      >
        <div class="spinner-border text-dark" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button
        v-else
        :disabled="disabledBtnSend"
        class="btn-default"
        @click.prevent="handleCadastro"
      >
        CADASTRAR
      </button>
    </div>
  </div>
</template>
<style scoped>
label {
  text-align: left;
}
.btn-add {
  color: white;
  text-decoration: underline;
}
.btn-close {
  position: absolute;
  right: -15px;
  top: 22px;
}
.label-file {
  position: relative;
  height: 46px;
  max-width: 523px;
  margin: auto;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex !important;
  text-transform: capitalize;
}
.label-file p {
  color: #8898aa;
  font-size: 0.9rem;
}
.label-file span {
  background: #17a264;
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 20px;
  border: solid 1px;
  border-color: white;
}

.text-img {
  max-width: 701px;
  margin: auto !important;
  font-size: 0.9rem;
}
.area-check {
  max-width: 618px;
  margin: auto;
  cursor: pointer;
  display: flex !important;
}

.checkbox {
  position: relative;
  appearance: none;
  width: 45px !important;
  height: 45px !important;
  cursor: pointer;
  border: solid 5px #17a264;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 0 !important;
}
.checkbox:before {
  content: "";
  position: absolute;
  height: 27px;
  width: 13px;
  opacity: 0;
  border-right: solid 6px #17a264;
  border-bottom: solid 6px #17a264;
  transform: rotate(45deg);
  top: 1px;
  transition: all 0.4s ease-in-out;
}

.select,
input {
  border-radius: 6px !important;
}
.checkbox:checked:before {
  opacity: 1;
}
.checkbox:hover .checkbox:focus {
  border: solid 5px #17a264 !important;
}
input[type="checkbox"]:hover,
input[type="checkbox"]:focus {
  border-color: #17a264;
}

.text-product {
  color: white;
  font-size: 14px;
}

@media (max-width: 576px) {
  .form-control {
    width: 90%;
  }
  .btn-close {
    right: 20px;
  }
}
</style>
