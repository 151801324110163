<script setup>
import { onMounted } from "vue";
// import ListagemComponent from "../../components/ListagemComponent.vue";
import Section1 from "../../components/Section1.vue";
import { storeGanhadores } from "../../store/ganhadores";
const ganhadoresStore = storeGanhadores()
// const typePremio = ref("diario");

// function setList(value) {
//   typePremio.value = value;
// }
onMounted(() => {
  ganhadoresStore.ActiongetGanhadores()
})
</script>

<template>
  <Section1 is-ganhadores></Section1>

  <div class="container my-5">
    <!-- <div class="row my-4 justify-content-center">
      <button
        class="btn-default col-md-3 mx-1 mb-4"
        :class="{ active: (typePremio === 'diario') }"
        @click.prevent="setList('diario')"
      >
        PRÊMIOS DIÁRIOS DE R$ 500
      </button>
      <button
        class="btn-default col-md-3 mx-1 mb-4"
        :class="{ active: typePremio === 'mensal' }"
        @click.prevent="setList('mensal')"
      >
        PRÊMIOS MENSAL DE R$ 10MIL
      </button>
      <button
        class="btn-default col-md-3 mx-1 mb-4"
        :class="{ active: (typePremio === 'final') }"
        @click.prevent="setList('final')"
      >
        SORTEIO FINAL DE 4 CARROS
      </button>
    </div>
    <div class="col-12">
      <ListagemComponent
        title="Sorteio final de 4 carros:"
        :columns="[
          {
            dataField: 'campo1',
            dataType: 'string',
            caption: 'NOME DO GANHADOR',
            width: '350px',
            headerAlign: 'center',
            dataAlign: 'center',
          },
          {
            dataField: 'campo2',
            dataType: 'string',
            caption: 'CIDADE - UF',
            width: '350px',
            headerAlign: 'center',
            dataAlign: 'center',
          },
          {
            dataField: 'campo3',
            dataType: 'string',
            caption: 'PRÊMIO',
            width: '350px',
            headerAlign: 'center',
            dataAlign: 'center',
          },
          {
            dataField: 'campo4',
            dataType: 'string',
            caption: 'DATA DO SORTEIO',
            width: '350px',
            headerAlign: 'center',
            dataAlign: 'center',
          },
        ]"
        :rows="[
          {
            campo1: 'GILSELE XXX MARIANO',
            campo2: 'GILSELE XXX MARIANO',
            campo3: 'GILSELE XXX MARIANO',
            campo4: '05/04/2022',
          },
          {
            campo1: 'GILSELE XXX MARIANO ',
            campo2: 'GILSELE XXX MARIANO ',
            campo3: 'GILSELE XXX MARIANO  ',
            campo4: '05/04/2022 ',
          },
          {
            campo1: 'GILSELE XXX MARIANO ',
            campo2: 'GILSELE XXX MARIANO ',
            campo3: 'GILSELE XXX MARIANO ',
            campo4: '05/04/2022 ',
          },
        ]"
      />
    </div> -->
    <h2>GANHADORES</h2>
    <div class="col-12 d-flex justify-content-center py-5 bg-white" v-html="ganhadoresStore.listaGanhadores" />
  </div>
</template>

<style>
td {
  width: 300px !important;
  border: solid 1px #000 !important;
  padding: 0 5px;
}
</style>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

h2 span {
  color: #53b95d;
  cursor: pointer;
}

h2 span:hover {
  text-decoration: underline;
}

.btn-default {
  max-width: 366px;
  font-size: 23px;
  background-color: #fff;
  color: #0d54aa;
}

.btn-default.active {
  background-color: #4fb75a;
  color: #fff;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}

.row-passos {
  z-index: 6;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
