<template>
  <main class="main-content position-relative max-height-vh-100 h-100">
    <component :is="layout">
      <router-view />
    </component>
  </main>
</template>
<script>
// import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
// import AppFooter from "@/examples/Footer.vue";
export default {
  name: "App",
  components: {
    // Login,
    // Sidenav,
    // Configurator,
    // Navbar,
    // AppFooter
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "Layout-default";
    },
  },
  mounted() {},
};
</script>
<style>
body {
  background-color: #9B0024 !important;
}

p {
  margin: 0 !important;
}

button {
  background-color: transparent;
  border: 0;
}

button:disabled {
  opacity: 0.7;
}

label {
  text-transform: uppercase;
  font-size: 20px !important;
  color: #fff !important;
  font-weight: bold;
  display: block !important;
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.bg-blue-2 {
  background-color: #0d2148 !important;
}

.bg-orange {
  background-color: #fda830 !important;
}

.vs__dropdown-toggle {
  border: 0 !important;
}

.cursor-auto {
  cursor: auto;
}

html,
body {
  color: #000000 !important;
}

input {
  height: 60px;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  text-align: center;
  width: 100%;
  border-color: transparent;
}

input::placeholder {
  color: #bcbcbc !important;
}

input[type="checkbox"] {
  width: 33px;
  height: 33px;
  margin: 0;
  margin-right: 16px;
}

input[type="datetime-local"],
input[type="date"] {
  outline: 0;
  position: relative;
  width: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("./assets/icons/calendar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none !important;
}

.text-2xl {
  font-size: 2rem!important;
}

.font-montSerrat {
  font-family: "Montserrat", sans-serif !important;
}

.btn-default {
  color: #9B0024;
  font-size: 24px;
  font-weight: 400;
  padding: 5px 40px;
  background-color: #ffffff;
  transition: all 0.3s;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
}

.border-inset {
  box-shadow: inset 0 0 1px 1px #9B002480;
  border: solid 1px #9B002420;
}
.form-control:focus{
  box-shadow: 0 0 0 0.25rem #FFDA4E90 !important;
}

.underline {
  text-decoration: underline;
}

.btn-default:hover {
  transform: scale(0.9);
}

.disabled {
  opacity: 0.5;
}

.swal2-checkbox {
  display: none !important;
}

.vs__open-indicator {
  margin-top: -5px;
}

.vs__clear {
  margin-top: -1px;
}

.anima-float {
  animation: float 1s ease-in-out infinite;
}

.appear-b {
  animation: appear-b .3s ease;
  animation-timeline: view();
  animation-range: cover 10% cover 50%;
}

.slide-r {
  animation: slide-r .3s ease;
  animation-timeline: view();
  animation-range: cover 0% cover 50%;
}
.slide-l {
  animation: slide-l .3s ease;
  animation-timeline: view();
  animation-range: cover 0% cover 50%;
}

@keyframes slide-l {
  0% {
    opacity: 0;
    translate: -100vw 0;
  }
  50% {
    opacity: 0;
    translate: -20vw 0;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@keyframes slide-r {
  0% {
    opacity: 0;
    translate: 100vw 0;
  }
  50% {
    opacity: 0;
    translate: 20vw 0;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@keyframes appear-b {
  0% {
    opacity: 0;
    translate: 0 1000px;
  }
  50% {
    opacity: 0;
    translate: 0 500px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes float {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 992px) {
  label {
    font-size: 16px !important;
  }

  /* .vs__open-indicator {
    display: none !important;
  } */

  /* .vs__clear {
    margin-top: -1px;
  } */
}

  .vs--searchable .vs__dropdown-toggle {
    margin-top: -11px !important;
  }

@media (max-width: 510px) {
  .btn-default {
    font-size: 20px;
  }
}

.text-primary- {
  color: #61af33 !important;
}

.btn-participar {
  background-color: #0d58aa;
  color: #fff;
}
.btn-participar-header {
  background-color: #021378;
  color: #fff;
}
</style>
