<template>
  <section class="bg-dark-blue pb-4">
    <div class="container">
      <Section1 is-home></Section1>
    </div>
    <LoginComponent @handleLogin="handleChangePassword" />
  </section>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { auth } from "../../store/auth";
import { storeCadastro } from "@/store/cadastro";

import Section1 from "../../components/Section1.vue";
import LoginComponent from "../../components/LoginComponent.vue";

const authStore = auth();
const cadastroStore = storeCadastro();
const route = useRoute();
const router = useRouter();

async function handleChangePassword() {
  const token = route.params.token;
  const { email, password } = cadastroStore.form;

  try {
    const res = await authStore.ActionNewPassword({ email, token, password });
    if (res.status) {
      await window.__Swal.fire({
        icon: "success",
        text: res.message,
      });

      router.push({ name: "Login" });
    } else if (!res.status) {
      window.__Swal.fire({
        icon: "error",
        text: res.message,
      });
    }
  } catch (error) {
    if (error.status !== 401) {
      if (error.data) {
        window.__Swal.fire({
          icon: "error",
          title: error.data.error.title || "Oops...",
          text: error.data.error.message,
        });
      } else {
        await window.__Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        });
      }
    }
  }
}

async function checkToken() {
  try {
    const token = route.params.token;
    const res = await authStore.ActionValidateToken({ token });
    if (!res.status) {
      await window.__Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.message,
      });
      router.push({ name: "Login" });
    }
  } catch (error) {
    console.log(error);
  }
}

onBeforeMount(() => {
  checkToken();
});
</script>

<style scoped></style>
