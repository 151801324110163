
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia'
import Maska from 'maska'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import "./assets/css/global.css";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueSweetalert2 from "vue-sweetalert2";

// import 'sweetalert2/src/sweetalert2.scss'
import 'bootstrap/dist/css/bootstrap.css'
import LayoutClient from "@/Layout/LayoutClient.vue";
import 'sweetalert2/src/sweetalert2.scss'

export const app = createApp(App);
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(router);
app.use(VueSweetalert2);
app.use(Maska);
app.use(pinia);
app.mount("#app");

app.component('LayoutClient', LayoutClient)
app.component('VSelect', vSelect)

import Swal from "sweetalert2";
window.__Swal = Swal