<script setup>
import { storeProdutos } from "../../store/produtosParticipantes";

import ListagemVerticalComponent from "../../components/ListagemVerticalComponent.vue";
import Section1 from "../../components/Section1.vue";
import { computed, onMounted } from "vue";

const produtosStore = storeProdutos();

function removeAcents(str) {
  if (!str)
    return ''
  return str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}

function sortName(a,  b,) {
  let nameA = removeAcents(a?.caption?.toLowerCase())
  let nameB = removeAcents(b?.caption?.toLowerCase())
  if (nameA < nameB)
    return -1

  if (nameA > nameB)
    return 1

  return 0
}

const computedProducts = computed(() => {
  const items = JSON.parse(JSON.stringify(produtosStore.columns))
  items.sort(sortName)
  return items
})

onMounted(() => {
  produtosStore.ActionGetProdutos();
});
</script>

<template>
  <div class="">
    <Section1 is-produtos-lista></Section1>
  </div>

  <div style="background: #FF0B46">
    <div class="container py-5">
      <div class="col-12">
        <ListagemVerticalComponent title="Confira os produtos participantes:" :columns="computedProducts"
          :rows="produtosStore.produtos" />
      </div>
    </div>
  </div>
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

h2 span {
  color: #53b95d;
  cursor: pointer;
}

h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}

.area-btn-participe {
  z-index: 6;
}

.row-passos {
  z-index: 6;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
