<template>
  <VuePdfApp style="height:70vh" :pdf="props.pdfUrl"></VuePdfApp>
</template>

<script setup>
// import { defineProps } from "vue";
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
const props = defineProps({
  pdfUrl: {
    type: String,
    required: true,
  },
});
</script>
