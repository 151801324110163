<script setup>
import { useRouter } from "vue-router";
// import { auth } from "../../store/auth";
import Section1 from "../../components/Section1.vue";
// const authStore = auth();

const router = useRouter();

// function handleParticipe() {
//   if (authStore.token) {
//     router.push({ name: "Cupons" });
//   } else {
//     router.push({ name: "Participe" });
//   }
// }
function handleParticipar() {
  router.push({ name: 'Participe' })
  // window.__Swal.fire({
  //     icon: "warning",
  //     title: "Atenção!",
  //     text: "Promoção encerrada, acompanhe os ganhadores!",
  //      }).then(() => {
  //       router.push({ name: "Ganhadores" });
  //   })
}
</script>

<template>
  <Section1 is-home></Section1>
  <section id="comoParticipar" class="position-relative">
    <img src="../../assets//images/lampada-l.png" class="lamp-l d-none d-md-block" alt="lâmpada ourolux"/>
    <img src="../../assets//images/lampada-r.png" class="lamp-r d-none d-md-block" alt="lâmpada ourolux"/>
    <div class="container">
      <div  class="row position-relative py-5">
        <div class="col-12 d-flex justify-content-center">
          <img
            class="img-fluid d-none d-md-block"
            src="../../assets/images/como-participar-desk.png"
            alt="Como participar"
          />
          <img
            class="img-fluid d-md-none"
            src="../../assets/images/como-participar-mob.png"
            alt="Como participar"
          />
        </div>
          <div class="col-12 d-flex justify-content-center">
            <button
              class="btn-participar d-none d-md-block"
              @click="handleParticipar"
            >
              <img
                class="img-fluid"
                src="../../assets/images/btn-participar.png"
                alt="Como participar"
              />
            </button>
          </div>
      </div>
    </div>
  </section>
  <section id="premios">
    <div class="container">
      <div class="row py-5">
        <div class="col-12">
          <p class="text-white text-center text-confira">CONFIRA OS PRÊMIOS</p>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img
              class="img-fluid slide-r d-none d-md-block"
              src="../../assets/images/premio-carro.png"
              alt="Carro"
            />
            <img
              class="img-fluid slide-r d-md-none"
              src="../../assets/images/premio-carro-mob.png"
              alt="Carro"
            />

            <img
              class="img-fluid slide-l d-none d-md-block"
              src="../../assets/images/premio-instantaneo.png"
              alt="Prêmios instantaneos"
            />
            <img
              class="img-fluid slide-l d-md-none"
              src="../../assets/images/premio-instantaneo-mob.png"
              alt="Prêmios instantaneos"
            />

            <img
              class="img-fluid slide-r d-none d-md-block"
              src="../../assets/images/premio-smartphone.png"
              alt="Smartphones"
            />
            <img
              class="img-fluid slide-r d-md-none"
              src="../../assets/images/premio-smartphone-mob.png"
              alt="Smartphones"
            />
          </div>

          <div class="d-flex justify-content-center">
            <button
              class="btn-participar anima-float"
              @click="handleParticipar"
            >
              <img
                class="img-fluid"
                src="../../assets/images/btn-participar-2.png"
                alt="Participar"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <div class="row">
      <div class="col-12 my-5">
        <h2 class="dobrar-chance">
          <span @click.prevent="router.push({ name: 'ListaProdutos' })">CLIQUE AQUI</span>
          PARA VER OS PRODUTOS PARTICIPANTES.
        </h2>
      </div>
    </div> -->
</template>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

#comoParticipar {
  background-color: #ff0b46;
}

h2 span {
  color: #f7d95a;
  cursor: pointer;
}

h2 span:hover {
  text-decoration: underline;
}

#premios {
  background-image: url("@/assets/images/bg1.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-participar {
  background-color: transparent;
}

.btn-participar img {
  max-width: 352px;
}

.btn-participe {
  background-color: #184091;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}

.area-btn-participe {
  z-index: 6;
  margin-top: -50px;
}

.row-passos {
  z-index: 6;
  max-width: 1000px;
  margin: auto;
}

.row-passos img {
  max-width: 200px;
}

.img-seta {
  position: absolute;
  left: -107px;
  top: -80%;
  animation: seta 0.4s ease-in-out infinite;
}

.text-confira {
  font-size: 56px;
  font-weight: 500;
}

.lamp-l {
  position: absolute;
  top: 0;
  left: 0;
  animation: appear-l .5s ease;
  animation-timeline: view();
  animation-range: cover 0% cover 50%;
}
.lamp-r {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: appear-r .5s ease;
  animation-timeline: view();
  animation-range: cover 0% cover 50%;
}

@keyframes appear-r {
  from {
    opacity: 0;
    translate: 200px 0;
  }
  to {
    opacity: 1;
    translate: 0 0;
  }
}
@keyframes appear-l {
  from {
    opacity: 0;
    translate: -200px 0;
  }
  to {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes seta {
  0% {
    transform: translate3d(-30px, 0, 0);
  }

  50% {
    transform: translate3d(0, 20px, 0) scale(1.1);
  }

  100% {
    transform: translate3d(-30px, 0, 0);
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 20px;
  }

  .row-passos {
    /* gap: 20px; */
  }

  .img-seta {
    display: none;
  }

  .area-btn-participe {
    z-index: 6;
    margin-top: 0px;
  }
}

@media (max-width: 578px) {
  .row-passos div {
    margin-bottom: 25px;
  }
}
</style>
