<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
// const route = useRoute();
const props = defineProps({
  isGanhadores: { type: Boolean, default: false },
  isHome: { type: Boolean, default: false },
  isProdutosLista: { type: Boolean, default: false },
  isRegulamento: { type: Boolean, default: false },
  isPrivacidade: { type: Boolean, default: false },
  isCupons: { type: Boolean, default: false },
});
const router = useRouter();

const heighBg = computed(() => {
  let height = '1148px'
  if (props.isGanhadores || props.isRegulamento)
    height = 'auto'
  return height
})

const bgPosition = computed(() => {
  let position = 'center center'
  if (props.isCupons)
    position = '25% -181px'
  return position
})

function handleParticipar() {
  router.push({ name: 'Participe' })
  // window.__Swal.fire({
  //     icon: "warning",
  //     title: "Atenção!",
  //     text: "Promoção encerrada, acompanhe os ganhadores!",
  //      }).then(() => {
  //       router.push({ name: "Ganhadores" });
  //   })
}
</script>
<template>
  <div class="section-1">
    <img class="img-fluid confetes d-none d-md-block" src="../assets/images/confetes.png" alt="Confetes" />
    <div class="container position-relative">
      <div class="d-flex">

        <div v-if="props.isHome" class="position-relative">

          <img class="img-fluid w-95 desktop promo-img-desktop anima-float" src="../assets/images/produto-dobra.png"
            alt="Capanha" />
          <!-- <img v-if="props.isHome" class="img-fluid produtos" src="../assets/images/nome-campanha.png" alt="Produtos" /> -->

          <!-- images anima -->
          <!-- <div class="desktop">
          <img
            class="img-0 img-moeda-0"
            src="../assets/images/img-anima-0.png"
            alt=""
          />
          <img
            class="img-1 img-moeda-1"
            src="../assets/images/img-anima-2.png"
            alt=""
          />
          <img
            class="img-2 img-moeda-2"
            src="../assets/images/img-anima-1.png"
            alt=""
          />
          <img class="img-3" src="../assets/images/img-anima-3.png" alt="" />
          <img class="img-4" src="../assets/images/img-anima-4.png" alt="" />
          <img class="img-5" src="../assets/images/img-anima-5.png" alt="" />
          <img class="img-6" src="../assets/images/img-anima-6.png" alt="" />
          <img class="img-7" src="../assets/images/img-anima-7.png" alt="" />
          <img class="img-8" src="../assets/images/img-anima-8.png" alt="" />
        </div> -->
          <!-- end images anima -->
        </div>
          <div class="d-flex flex-column align-items-center justify-content-center flex-1"
            :class="{ 'position-img2': props.isHome }">
            <img class="img-fluid produtos" src="../assets/images/nome-campanha.png" alt="Show de prêmios" />
            <div class="d-flex justify-content-center">
              <button @click="handleParticipar">
                <img class="img-fluid" src="../assets/images/btn-participe.png" alt="Participar" />
              </button>
            </div>
          </div>
      </div>

      <div v-if="!props.isGanhadores && !props.isRegulamento" class="mt-8">
        <img v-if="$route.name === 'ListaProdutos'" class="img-fluid produtos mb-5 mb-md-0" src="../assets/images/produtos-desk.png" alt="Show de prêmios" />
        <img v-else-if="$route.name !== 'Cupons'" class="img-fluid d-none d-md-block appear-b" src="../assets/images/premios-desk.png" alt="prêmios" />
        <div v-if="$route.name !== 'ListaProdutos'" class="d-md-none d-flex justify-content-center flex-column align-items-center w-100">
          <img class="img-fluid" src="../assets/images/carro-mob.png" alt="prêmios" />
          <img class="img-fluid" src="../assets/images/premios-instantaneos-mob.png" alt="prêmios" />
          <img class="img-fluid" src="../assets/images/premio-smartphone-mob.png" alt="prêmios" />
        </div>
      </div>
    </div>

    <slot v-if="$slots.contentSection1" name="contentSection1"/>

  </div>
</template>

<style scoped>
.section-1 {
  background-image: url("@/assets/images/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: v-bind(bgPosition);
  padding-top: 150px;
  min-height: v-bind(heighBg);
  margin-top: 53px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
}

.promo-img-desktop {
  max-width: 550px;
}

.position-img2 {
  margin-left: -150px;
}

.vibrate {
  animation: vibrate-1 0.1s linear infinite;
}
.btn-participe:hover {
  animation: vibrate-1 0.1s linear infinite;
}

.img-0 {
  position: absolute;
  top: 105px;
  left: 107px;
}

.img-1 {
  position: absolute;
  top: 24px;
  left: 121px;
}

.img-2 {
  position: absolute;
  top: 147px;
  left: -5px;
}

.img-3 {
  position: absolute;
  top: 30px;
  left: 232px;
  animation: fadeIn 1.8s ease-in-out infinite;
}

.img-4 {
  position: absolute;
  top: 14px;
  right: 138px;
  animation: fadeIn 2s ease-in-out infinite;
}

.img-5 {
  position: absolute;
  top: 14px;
  right: 60px;
  animation: fadeIn 0.8s ease-in-out infinite;
}

.img-6 {
  position: absolute;
  top: 48px;
  right: -5px;
  animation: fadeIn 0.9s ease-in-out infinite;
}

.img-7 {
  position: absolute;
  top: 211px;
  right: -17px;
  animation: fadeIn 1.8s ease-in-out infinite;
}

.img-8 {
  position: absolute;
  top: 277px;
  left: 92px;
  animation: fadeIn 2.8s ease-in-out infinite;
}

.produtos {
  /* margin-top: -98px;
  margin-left: 39px; */
}

.desktop {
  display: initial;
}

.mobile {
  display: none;
}

.scooter-mobile {
  display: none;
}

.premio-desktop {
  display: initial;
}

.logo-float {
  position: absolute;
  top: -82px;
  right: 159px;
}

.logo-float img {
  width: 130px;
}

.confetes {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-b 4s linear infinite;
}

@keyframes fade-b {
  0% {
    opacity: .7;
    translate: 0 -570px;
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    translate: 0 500px;
  }
}

@keyframes vibrate-1 {
  0% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(-1deg);
  }
}

@media (max-width: 1399px) {
  .ponteiro {
    position: absolute;
    left: 58%;
    top: 1%;
    animation: vibrate-1 0.1s linear infinite;
    width: 80px;
  }
}

@media (max-width: 1399px) {
  .ponteiro {
    position: absolute;
    left: 58%;
    top: 2%;
    animation: vibrate-1 0.1s linear infinite;
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .produtos {
    margin-top: 0;
  }

  .position-img2 {
    margin-left: 0px;
  }

  .desktop,
  .ponteiro {
    display: none;
  }

  .mobile {
    display: initial;
  }
}

.img-moeda-0 {
  animation: fadeIn 2s ease-in-out infinite;
}

.img-moeda-1 {
  animation: fadeIn 1s ease-in-out infinite;
}

.img-moeda-2 {
  animation: fadeIn 1.5s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-80%);
    opacity: 0;
  }

  50% {
    /* transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .section-1 {
    /* background-image: url("@/assets/images/bg-section1-mobile.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-top: 0;
    min-height: auto;
  }

  .logo-float {
    display: none;
  }

  .scooter-mobile {
    display: initial;
  }

  .premio-desktop {
    display: none;
  }
}
</style>
