<script setup>
import { onMounted, ref } from "vue";
import Section1 from "../../components/Section1.vue";
import { http } from "@/services/api";

const privacidade = ref("");
function getPolitica() {
  const url = "/privacidade";
  http.get(url).then((res) => {
    privacidade.value = res.data.texto.text;
  });
}
onMounted(() => {
  getPolitica();
});
</script>

<template>
  <Section1 is-privacidade></Section1>

  <div class="container my-5">
    <h1 class="my-4 text-white">Política de privacidade</h1>
    <div class="area-privacidade card border-0">
      <div class="p-2" v-html="privacidade"></div>
    </div>
  </div>
</template>

<style scoped>
.area-privacidade{
  max-height: 600px;
  overflow: auto;
}
h1 {
  font-size: 30px;
  color: #000000;
  text-align: center;
}
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
h2 span {
  color: #53b95d;
  cursor: pointer;
}
h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}
.area-btn-participe {
  z-index: 6;
}
.row-passos {
  z-index: 6;
}

.card {
  border-radius: 0;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
