<template>
  <footer>
    <!--  <header
      :style="`background-color: ${
        $route.name !== 'Home' ? '#E48F31' : '#04377e'
      }`"
    >
      <div class="container">
        <div class="row text-center justify-content-center">
          <router-link :to="{ name: 'Home' }" class="col-12 col-md-3"
            >Política de privacidade</router-link
          >
           <router-link :to="{ name: 'Regulamento' }" class="col-12 col-md-3"
            >Termos de Uso</router-link
          > 
          <router-link :to="{ name: 'Home' }" class="col-12 col-md-3"
            >Fale conosco</router-link
          >
        </div>
      </div>
    </header>-->
    <section class="pb-4">
      <div v-if="$route.name !== 'Contato'" class="area-duvida py-5">
          <div class="container">
            <Faq v-if="duvidas.length > 0" :faq="duvidas" />
            <p class="text-duvida d-flex flex-column flex-md-row align-items-center flex-wrap justify-content-center gap-2">AINDA POSSUI DÚVIDAS?
              <button
                class="rounded-pill cursor-pointer bg-white d-inline-flex align-items-center justify-content-center px-4 py-1"
                style="color:#9B0024" @click="handleContato">Fale conosco! </button>
            </p>
          </div>

      </div>
      <div class="container">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <div style="max-width: 985px">
            <div class="area-nav d-flex flex-wrap gap-3 gap-md-5">
              <router-link :to="{ name: 'Regulamento' }" class="">Regulamento</router-link>
              <router-link :to="{ name: 'Contato' }">Dúvidas</router-link>
              <router-link :to="{ name: 'Contato' }" class="">Fale conosco</router-link>
              <router-link :to="{ name: 'Privacidade' }" class="">Política de privacidade</router-link>
            </div>
            <p class="text-politica">
              <small>
                Período de compras/cadastros válidos: de 01.03.2024 a 29.05.2024. (*) Imagem de sugestão de uso do prêmio
                final no valor de R$ 37.200,00 entregue em Certificado em barras em ouro. (**) Sugestão de uso do prêmio
                mensal de R$ 4.500,00, cada, entregue em créditos, via PicPay, sem função saque. (***) Prêmios
                instantâneos de R$ 500,00, cada, entregue em créditos, via PicPay, sem função saque. Limite de: 50
                (cinquenta) Números da Sorte por CPF, na vigência da promoção. Na compra de 1 produto participante o
                consumidor terá direito a receber 1 (um) Número da Sorte e, incluindo, ao menos 1 (um) produto acelerador
                dobra sua chance. CERTIFICADOS DE AUTORIZAÇÃO SRE/ME Nº 04.032435/2024 (mod. ass. sorteio) e
                05.032436/2024 (mod. ass. vale-brinde). Consulte Regulamentos em wwww.showdepremiosourolux.com.br. Guarde
                os cupons originais de compra para receber o prêmio. Limites de contemplação por CPF/modalidade, na
                vigência da promoção: 1 (um) prêmio mensal e 1 (um) prêmio instantâneo.
              </small>
            </p>
          </div>
          <div>
            <div class="d-flex justify-content-center"><img src="../assets/images/logo.png" alt="Logo" /></div>
            <div class="social-area d-flex align-items-center">
              <!-- <a
                  class="me-2"
                  href="https://www.facebook.com/Ourolux-Food-Service-322172684957473/?modal=admin_todo_tour"
                  target="_blank"
                  title="Curta nossa página no Facebook"
                  ><img src="../assets/images/face.png" alt="Facebook"
                /></a> -->
              <a class="me-2" href="https://www.youtube.com/channel/UCLBrxkdfMPb8ssZbbozjUPA" target="_blank"
                title="Ourolux no youtube"><i class="fa-brands fa-youtube"></i></a>
              <a class="me-2" href="https://www.linkedin.com/company/ourolux/" target="_blank"
                title="Ourolux no linkedin"><i class="fa-brands fa-linkedin-in"></i></a>
              <a class="me-2" href="https://www.instagram.com/ourolux.oficial/" target="_blank"
                title="Ourolux no linkedin"><i class="fa-brands fa-instagram"></i></a>
              <a class="me-2" href="https://ourolux.com.br/" target="_blank" title="Conheça no site Ourolux"><i
                  class="fa-solid fa-globe"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Faq from "@/components/Faq.vue";
import { http } from "@/services/api";

const router = useRouter();
const duvidas = ref([]);

function handleContato() {
  router.push({ name: "Contato" });
}

async function getDuvidas() {
  const url = "/duvidas";
  const res = await http.get(url);
  duvidas.value = res.data.duvidas;
}

onMounted(() => {
  getDuvidas();
});
</script>

<style scoped>
footer {
  position: relative;
  background-color: #FF0B46;
}

/* footer::before {
  content: "";
  background-image: url("../assets/images/header/rope.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 11.4px;
  width: 100vw;
  position: absolute;
  top: -10px;
  left: -5px;
} */

footer header a {
  color: #fff;
}

.text-politica {
  color: #fff;
  font-size: 16px;
  line-height: 1;
  text-align: left;
}

.social-area a {
  color: #FF0B46;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  display: block;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
}

.area-nav a {
  font-size: 23px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
}

.area-duvida {
  background-color: #9B0024;
}

.text-duvida {
  font-size: 40px;
}

.container div {
  padding: 23px 0;
}

section .btn-faq {
  display: flex;
  justify-content: center;
  padding-top: 18px;
}

section p {
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}

/* .btn-acessar {
  color: #000000;
  font-size: 32px;
  font-weight: bolder;
  padding: 8px;
  width: 100%;
  max-width: 369px;
  background-color: #a8d32c;
  transition: all 0.3s;
}

button:hover {
  transform: scale(0.9);
} */
.img-1 {
  position: absolute;
  left: -60px;
  top: 45px;
}

.img-2 {
  position: absolute;
  right: -120px;
  top: -25px;
}

.footer-duvida {
  display: block;
  font-size: 49px;
}

.footer-fale {
  display: block;
  font-size: 49px;
  text-transform: uppercase;
  color: #fff;
}

.footer-click {
  display: block;
  font-size: 35px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .img-1 {
    position: absolute;
    left: -60px;
    top: 45px;
    display: none;
  }

  .img-2 {
    position: absolute;
    right: -120px;
    top: -25px;
    display: none;
  }

  .img-logo {
    width: 142px;
    margin: 0 !important;
  }

  .area-logo {
    gap: 20px;
  }
  .text-duvida {
  font-size: 24px;
}
}
</style>
