import { defineStore } from 'pinia'
import { ref } from 'vue'
import * as storage from "./storage";
import { http } from '@/services/api';

export const auth = defineStore('authStore', () => {
  const user = ref({})
  const token = ref('')


  function ActionDoLogin(payload) {
    return http.post('/login', payload).then(r => {                
        if(r.data.access_token)
          ActionSetToken(r.data.access_token)
    })
  }

    function ActionGetProfile() {
        return http.post('/profile').then(r => ActionSetUser(r.data))
    }

function ActionForgotPassword(payload) {
    const url = "/resetPassword"
    return http.post(url, payload)
}

function ActionValidateToken(payload) {
    const url = '/validateTokenReset'
    return http.post(url, payload).then(r => r.data)
}

function ActionNewPassword(payload) {
    const url = '/newPassword'
    return http.post(url, payload).then(r => r.data)

}

function ActionCheckToken() {
    const token = storage.getLocalToken();
    if (token.value) {
        return Promise.resolve(token.value);
    } else if (!token) {
        return Promise.reject(new Error("Token Inválido"));
    } else {
        ActionSetToken(token);
        ActionSetUser(storage.getLocalUsuario());
        return Promise.resolve(token);
    }
}
function ActionSetUser(payload) {
    user.value = payload;
}
function ActionSetToken(payload) {
    storage.setLocalToken(payload);
    storage.setHeaderToken(payload);
    token.value = payload
}
function ActionSingnout() {
    storage.setHeaderToken("");
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
    storage.deleteLocalPlan();
    ActionSetUser({});
    ActionSetToken("");
}

  return {user, token, ActionSingnout, ActionSetToken, ActionSetUser, ActionCheckToken, ActionNewPassword, ActionValidateToken, ActionForgotPassword, ActionGetProfile, ActionDoLogin }
},
  {
    persist: [
      {
        paths: ['token'],
        storage: localStorage,
      },
    ],
  },
)
