<script setup>
// import { onMounted } from "vue";
import Section1 from "../../components/Section1.vue";
import PdfViewer from '../../components/PdfViewer.vue';

let linkPdf = `${process.env.VUE_APP_URL}/reg/regulamento.pdf`
linkPdf = linkPdf.replace('/api/reg', '/reg')
</script>

<template>
  <Section1 is-regulamento></Section1>

  <div class="container my-5">
    <div class="area-pdf card border-0">
      <h1 class="my-4">Confira o regulamento completo:</h1>
      <!-- <div id="adobe-dc-view" style="height: 600px; width: 100%"></div> -->
      <pdf-viewer :pdf-url="linkPdf" />
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px;
  color: #000000;
  text-align: center;
}
h2 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
h2 span {
  color: #53b95d;
  cursor: pointer;
}
h2 span:hover {
  text-decoration: underline;
}

.btn-participe {
  background-color: #4fb75a;
  color: white;
  font-weight: bolder;
  font-size: 40px;
  padding: 8px 45px;
  z-index: 6;
}

.img-arc {
  position: absolute;
  top: 50%;
  z-index: 5;
}
.area-btn-participe {
  z-index: 6;
}
.row-passos {
  z-index: 6;
}

.card {
  border-radius: 0;
}

@media (max-width: 768px) {
  .row-passos {
    gap: 20px;
  }
}
</style>
